<template>
  <div class="property">
    <carousel
      v-if="data.planImages.length > 1"
      class="property__carousel"
      :per-page="1"
      :pagination-size="5"
      :pagination-padding="5"
      :speed="100"
    >
      <slide
        v-for="image, idx in data.planImages"
        :key="idx"
      >
        <div class="property__image">
          <!-- <a
            :href="image.source"
            target="_blank"
            class="property__image__link d-none d-md-block"
          /> -->
          <img :src="image.source">
        </div>
      </slide>
    </carousel>
    <template v-else-if="data.preset">
      <div class="property__image">
        <a
          :href="data.preset"
          target="_blank"
          class="property__image__link d-none d-md-block"
        />
        <img :src="data.preset">
      </div>
      <div class="property__pagination"></div>
    </template>
    <div class="property__header">
      <div>
        <h2
          v-if="data.status === 'AVAILABLE' && data.price.value"
          class="property__price"
        >
          {{ data.price.value | formatPrice }}
        </h2>
        <p
          v-if="data.status === 'AVAILABLE' && data.price.pricePerMeter"
          class="property__pricePerMeter"
        >
          {{ data.price.pricePerMeter | formatPrice }}/м²
        </p>
      </div>
      <div
        v-if="data.status === 'AVAILABLE'"
        class="property__actions"
      >
        <AtomButton
          :color="isFavorite ? 'red' : null"
          :class="{ tada: isFavoriteClicked === data.id }"
          variant="pill"
          :title="isFavorite ? $t('favorite.remove') : $t('favorite.add')"
          @click.prevent="$emit('clickFavorite', data)"
        >
          <heart-icon />
        </AtomButton>
      </div>
    </div>
    <div class="property__body border p-3 mt-3">
      <div class="property__row">
        <div class="property__label">{{ $t('property.area') }}:</div>
        <div class="property__value">{{ data.area.area_total }} м²</div>
      </div>
      <div class="property__row">
        <div class="property__label">{{ $t('property.rooms-amount') }}:</div>
        <div class="property__value">{{ data.rooms_amount }}</div>
      </div>
      <div class="property__row">
        <div class="property__label">{{ $t('property.floor') }}:</div>
        <div class="property__value">{{ data.floor }}</div>
      </div>
    </div>
    <div
      v-if="!!$slots.footer"
      class="property__footer"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import AtomButton from '@/components/AtomButton'

export default {
  name: 'MoleculeProperty',

  components: {
    Carousel,
    Slide,
    AtomButton,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isFavoriteClicked: {
      type: Boolean,
      default: false,
    },

    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import '~@tabler/core/src/scss/variables';

  .property {
    $self: &;

    &__image {
      position: relative;
      height: 300px;

      #{$self}.card & {
        height: 250px;
      }

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        overflow: hidden;
      }

      &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__pagination {
      height: 35px;
    }

    &__header {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__price {
      margin-bottom: 0;
    }

    &__pricePerMeter {
      color: $gray-400;
      font-weight: 500;
      margin-bottom: 0;
    }

    &__row {
      + #{$self}__row {
        margin-top: .5rem;
      }

      display: flex;
      justify-content: space-between;
    }

    &__label {
      color: $gray-500;
    }

    &__value {
      font-weight: 500;
      font-size: 1rem;
    }

    &__footer {
      margin-top: 1rem;
    }
  }

  .VueCarousel {
    &-dot {
      &:focus {
        outline: none !important;
      }
      &--active {
        background-color: $blue !important;
      }
    }
  }
</style>
