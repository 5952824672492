<template>
  <div class="btn-list">
    <AtomButton
      v-if="this.$options.canShare"
      @click.prevent="click()"
      :style="{ marginRight: wide ? 0: false }"
    >
      <share-icon />
    </AtomButton>
    <AtomButton
      v-if="!this.$options.isTouchDevice"
      color="azure"
      @click.prevent="click('telegram')"
    >
      <brand-telegram-icon />
    </AtomButton>
    <AtomButton
      v-if="!this.$options.isTouchDevice"
      color="teal"
      @click.prevent="click('whatsapp')"
    >
      <brand-whatsapp-icon />
    </AtomButton>
    <AtomButton
      color="primary"
      :class="{ col: wide }"
      :loading="loading"
      @click.prevent="download"
    >
      <file-download-icon />{{ text || $t('action.download') }}
    </AtomButton>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { functions } from '@/firebase'
import { isTouchDevice } from '@/helpers'
import AtomButton from '@/components/AtomButton'

export default {
  name: 'MoleculeShare',

  components: {
    AtomButton,
  },

  data: () => ({
    loading: false,
  }),

  props: {
    numbers: {
      type: Array,
      required: true,
    },

    text: {
      type: String,
    },

    wide: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    telegram () {
      return 'https://t.me/share/url?url=' + this.link
    },

    whatsapp () {
      return 'https://web.whatsapp.com/send?text=' + this.link
    },

    email () {
      return 'https://www.addtoany.com/add_to/email?linkurl=' + this.link
    },

    link () {
      const url = new URL('/download/presentation?numbers=' + this.numbers.join(), location.origin)
      return url
    },
  },

  methods: {
    ...mapMutations(['setError']),

    async click (provider) {
      const shareData = {
        title: this.$t('presentation.download'),
        url: this.link,
      }
      if (this.$options.isTouchDevice) {
        if (navigator.share) {
          navigator.share(shareData)
        }
      } else {
        if (provider && this[provider]) {
          window.open(this[provider], '_blank')
        } else {
          navigator.share(shareData)
        }
      }
    },

    async download () {
      try {
        this.loading = true
        const downloadPresentation = functions.httpsCallable('presentation-download')
        const response = await downloadPresentation(this.numbers)
        const [url] = response.data.url

        const link = document.createElement('a')
        link.style.display = 'none'
        link.setAttribute('target', '_blank')
        link.setAttribute('href', url)
        link.setAttribute('download', true)
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (e) {
        this.setError(e)
      } finally {
        this.loading = false
      }
    },
  },

  isTouchDevice: isTouchDevice(),
  canShare: !!navigator.share,
}
</script>
